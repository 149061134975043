/* sonar-light.css */
:root {
  --bg-color: #f8f9fa;
  --sidebar-bg: #ffffff;
  --card-bg: #ffffff;
  --text-color: #212529;
  --text-muted: #6c757d;
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --border-radius: 8px;
  --box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --transition: all 0.3s ease;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.dashboard {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: var(--sidebar-bg);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  box-shadow: var(--box-shadow);
  z-index: 1000;
}

.sidebar__title {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.sidebar__nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sidebar__link {
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.sidebar__link:hover {
  background-color: var(--primary-color);
  color: white;
}

.refresh-button {
  margin-top: 30vh;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  font-weight: 600;
}

.refresh-button:hover {
  background-color: #0056b3;
}

.main-content {
  flex-grow: 1;
  padding: 2rem;
  margin-left: 250px;
  overflow-y: auto;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.severity-gauge {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circular-chart {
  width: 100px;
  height: 100px;
}

.circle-bg {
  fill: none;
  stroke: #e9ecef;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage {
  fill: var(--text-color);
  font-size: 0.5em;
  text-anchor: middle;
  font-weight: 700;
}

.section-title {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.metrics-grid,
.severities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.metric-card,
.severity-card {
  background-color: var(--card-bg);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  border: 1px solid #e9ecef;
}

.metric-card:hover,
.severity-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.metric-card__name,
.severity-card__title {
  color: var(--text-muted);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  word-wrap: break-word;
  white-space: normal;
}


.metric-card__value,
.severity-card__value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

.code-review-section,
.code-diff-section {
  background-color: var(--card-bg);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  margin-top: 2rem;
  box-shadow: var(--box-shadow);
  border: 1px solid #e9ecef;
}

.code-review,
.code-diff {
  font-family: 'Fira Code', monospace;
  font-size: 0.9rem;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-x: auto;
  color: var(--text-color);
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: var(--border-radius);
  text-align: left;
}

.loader {
  border: 4px solid #e9ecef;
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: static;
    padding: 1rem;
  }

  .main-content {
    margin-left: 0;
    padding: 1rem;
  }
}

.metric-card.important-metric {
  border: 2px solid var(--warning-color);
  /* Change border color for important metrics */
  background-color: #fff9e6;
  /* Light yellow background for important metrics */
}

.important-indicator {
  color: var(--warning-color);
  margin-left: 0.5rem;
  font-size: 1rem;
  vertical-align: middle;
}